.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-size: 24px;
  line-height: 1.25;
  cursor: pointer;
  color: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  padding-left: 30px;
  padding-right: 10px;
  background-size: 18px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.2s;
}
.button:hover,
.button:focus {
  color: #d41b16;
  padding-left: 40px;
  padding-right: 0;
}
@media (max-width: 1439px) {
  .button {
    font-size: 18px;
    line-height: 1.33333333;
    background-position: 0 2px;
  }
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation div.sub1 > .item {
  position: relative;
  margin-right: 140px;
  margin-bottom: -4px;
}
.navigation div.sub1 > .item.exit {
  margin-right: 0;
}
.navigation div.sub1 > .item:hover > .menu,
.navigation div.sub1 > .item:focus > .menu {
  color: #000;
}
.navigation div.sub1 > .item > .menu {
  font-size: 25px;
  line-height: 1.2;
  font-weight: 500;
  color: #d41b16;
  padding-bottom: 28px;
}
.navigation div.sub1 > .item > .menu.path {
  color: #000;
}
@media (max-width: 1439px) {
  .navigation div.sub1 > .item {
    margin-right: 80px;
  }
  .navigation div.sub1 > .item > .menu {
    font-size: 24px;
    line-height: 1.25;
  }
}
.navigation div.sub2 {
  position: absolute;
  left: -30px;
  top: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  min-width: calc(100% + 60px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
  height: 0;
  overflow: hidden;
  transition: all 0.4s;
}
.navigation div.sub2 > .item {
  margin-top: 16px;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.1s;
}
.navigation div.sub2 > .item.init {
  margin-top: 30px;
}
.navigation div.sub2 > .item.exit {
  margin-bottom: 30px;
}
.navigation div.sub2 > .item > .menu {
  white-space: nowrap;
  font-size: 22px;
  line-height: 1.09090909;
  color: #d41b16;
}
.navigation div.sub2 > .item > .menu:hover,
.navigation div.sub2 > .item > .menu:focus,
.navigation div.sub2 > .item > .menu.path {
  color: #000;
}
.navigation div.sub1 > .item:hover div.sub2 {
  height: var(--js-elementHeight);
}
.navigation div.sub1 > .item:hover div.sub2 > .item {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.2s 0.2s;
}
.section--two .content {
  width: calc(100% + 50px);
  margin-left: -25px;
}
.section--one .subcontent.subcontent--1 {
  width: calc((((100% - 100px) / 3) * 2) + 50px);
}
.section--one .subcontent.subcontent--2 {
  float: right;
  width: calc((100% - 100px) / 3);
}
.section--two .subcontent {
  width: calc((100% / 3) - 50px);
  margin-left: 25px;
  margin-right: 25px;
}
.section--two .subcontent.subcontent--1 {
  width: calc(100% - 50px);
}
.area--oneB {
  margin-top: calc(var(--spaceAfterHeader) - var(--spaceTotal));
}
/*# sourceMappingURL=./screen-large.css.map */